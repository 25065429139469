import { render, staticRenderFns } from "./MessageListMessageInline.vue?vue&type=template&id=706c3037&scoped=true&functional=true&"
import script from "./MessageListMessageInline.vue?vue&type=script&lang=js&"
export * from "./MessageListMessageInline.vue?vue&type=script&lang=js&"
import style0 from "./MessageListMessageInline.vue?vue&type=style&index=0&id=706c3037&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "706c3037",
  null
  
)

export default component.exports