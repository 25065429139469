"use strict";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
'kiwi public';

var _interopRequireWildcard = require("@babel/runtime-corejs3/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _parseInt2 = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/parse-int"));

var _map = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/map"));

var _indexOf = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/index-of"));

var _keys = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/keys"));

var _forEach = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/for-each"));

var settingTools = _interopRequireWildcard(require("@/libs/settingTools"));

var _lodash = _interopRequireDefault(require("lodash"));

var _default = {
  data: function data() {
    return {
      filterString: ''
    };
  },
  computed: {
    filteredSettings: function filteredSettings() {
      var _context;

      var settings = this.settings;
      var filter = this.filterString.toLowerCase();
      var out = [];
      (0, _forEach.default)(_context = (0, _keys.default)(settings)).call(_context, function (key) {
        var _context2;

        var value = settings[key];

        if ((0, _indexOf.default)(_context2 = value.key.toLowerCase()).call(_context2, filter) !== -1) {
          out.push(value);
        }
      });
      return out;
    },
    settings: function settings() {
      var _context3;

      var out = {};
      var base = [];
      settingTools.buildTree(out, base, this.$state.getSetting('settings'), false);
      settingTools.buildTree(out, base, this.$state.getSetting('user_settings'), true);
      return _lodash.default.orderBy((0, _map.default)(_context3 = (0, _keys.default)(out)).call(_context3, function (key) {
        return out[key];
      }), [function (o) {
        return o.key.split('.').length - 1;
      }, 'key'], ['asc']);
    }
  },
  methods: {
    resetValue: function resetValue(event, settingKey) {
      var newVal = this.$state.getSetting('settings.' + settingKey);

      if (!newVal) {
        newVal = null;
      }

      this.$state.setting(settingKey, newVal);
    },
    updateSetting: function updateSetting(event, settingKey) {
      var target = event.target;
      var val = target.type === 'checkbox' ? target.checked : target.value;

      switch (target.type) {
        case 'checkbox':
          val = target.checked;
          break;

        case 'number':
          val = (0, _parseInt2.default)(target.value, 10);
          break;

        default:
          val = target.value;
          break;
      }

      if (this.$state.setting(settingKey) === val) {
        return;
      }

      this.$state.setting(settingKey, val);
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["SettingsAdvanced"]
window._kiwi_exports.components.SettingsAdvanced = exports.default ? exports.default : exports;
