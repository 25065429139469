"use strict";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
'kiwi public';

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;
var _default = {
  components: {},
  props: ['buffer'],
  data: function data() {
    return {};
  },
  computed: {
    settingAlertOn: {
      get: function getSettingAlertOn() {
        return this.buffer.setting('alert_on');
      },
      set: function setSettingAlertOn(val) {
        return this.buffer.setting('alert_on', val);
      }
    },
    settingMuteSound: {
      get: function getSettingAlertOn() {
        return this.buffer.setting('mute_sound');
      },
      set: function setSettingAlertOn(val) {
        return this.buffer.setting('mute_sound', val);
      }
    },
    settingHideMessageCount: {
      get: function getsettingHideMessageCount() {
        return this.buffer.setting('hide_message_counts');
      },
      set: function setsettingHideMessageCount(val) {
        return this.buffer.setting('hide_message_counts', val);
      }
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["BufferSettings"]
window._kiwi_exports.components.BufferSettings = exports.default ? exports.default : exports;
