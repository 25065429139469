var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"kiwi-wrap kiwi-theme-bg",class:{
        'kiwi-wrap--statebrowser-drawopen': _vm.stateBrowserDrawOpen,
        'kiwi-wrap--monospace': _vm.$state.setting('useMonospace'),
        'kiwi-wrap--touch': _vm.$state.ui.is_touch,
    },attrs:{"data-activebuffer":_vm.buffer ? _vm.buffer.name.toLowerCase() : ''},on:{"click":_vm.emitDocumentClick,"!paste":function($event){return _vm.emitBufferPaste($event)}}},[_c('link',{attrs:{"href":_vm.themeUrl,"rel":"stylesheet","type":"text/css"}}),_c('link',{attrs:{"href":"static/styles/styles.css","rel":"stylesheet","type":"text/css"}}),(!_vm.hasStarted || (!_vm.fallbackComponent && _vm.networks.length === 0))?[_c(_vm.startupComponent,{tag:"component",on:{"start":_vm.startUp}})]:[_c('state-browser',{attrs:{"networks":_vm.networks,"sidebar-state":_vm.sidebarState}}),_c('div',{staticClass:"kiwi-workspace",class:{
                'kiwi-workspace--disconnected': _vm.network && _vm.network.state !== 'connected',
                'kiwi-workspace--compact': _vm.$state.ui.layout === 'compact',
            },on:{"click":function($event){_vm.stateBrowserDrawOpen = false}}},[_c('div',{staticClass:"kiwi-workspace-background"}),(!_vm.activeComponent && _vm.network)?[_c('container',{attrs:{"network":_vm.network,"buffer":_vm.buffer,"sidebar-state":_vm.sidebarState},scopedSlots:_vm._u([(_vm.mediaviewerOpen)?{key:"before",fn:function(){return [_c('media-viewer',{staticClass:"kiwi-main-mediaviewer",attrs:{"url":_vm.mediaviewerUrl,"component":_vm.mediaviewerComponent,"component-props":_vm.mediaviewerComponentProps,"is-iframe":_vm.mediaviewerIframe},on:{"close":function($event){return _vm.$state.$emit('mediaviewer.hide', { source: 'user' });}}})]},proxy:true}:null],null,true)}),(_vm.buffer.show_input)?_c('control-input',{attrs:{"container":_vm.networks,"buffer":_vm.buffer}}):_vm._e()]:(!_vm.activeComponent)?_c(_vm.fallbackComponent,_vm._b({tag:"component"},'component',_vm.fallbackComponentProps,false)):_c(_vm.activeComponent,_vm._b({tag:"component"},'component',_vm.activeComponentProps,false))],2)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }