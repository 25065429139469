var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"kiwi-container",class:{
    /* 'kiwi-container-' + bufferType: true, */
    'kiwi-container--sidebar-drawn': _vm.sidebarState.isDrawn,
    'kiwi-container--sidebar-open': _vm.sidebarState.isOpen,
    'kiwi-container--no-sidebar': _vm.buffer && _vm.buffer.isSpecial,
}},[(_vm.buffer)?[(_vm.$state.ui.layout !== 'compact')?_c('div',{staticClass:"kiwi-container-toggledraw-statebrowser",on:{"click":function($event){$event.stopPropagation();return _vm.toggleStateBrowser($event)}}},[_c('div',{staticClass:"kiwi-container-toggledraw-statebrowser-messagecount",class:[
                    _vm.unreadMessages.highlight ?
                        'kiwi-container-toggledraw-statebrowser-messagecount--highlight' :
                        '' ]},[_c('span',{staticClass:"kiwi-container-statebrowser-messagecount-alert"},[_vm._v(" "+_vm._s(_vm.unreadMessages.count > 999 ? '999+' : _vm.unreadMessages.count)+" ")]),_vm._m(0)])]):_vm._e(),_c('container-header',{attrs:{"buffer":_vm.buffer,"sidebar-state":_vm.sidebarState}}),_vm._t("before"),(_vm.buffer.getNetwork().state !== 'connected' && !_vm.buffer.isServer())?_c('not-connected',{attrs:{"buffer":_vm.buffer,"network":_vm.buffer.getNetwork()}}):_vm._e(),_c('div',{staticClass:"kiwi-container-content"},[(_vm.buffer.isServer())?[_c('server-view',{attrs:{"network":_vm.network}})]:[_c('message-list',{attrs:{"buffer":_vm.buffer}}),(!_vm.buffer.isSpecial())?_c('sidebar',{attrs:{"network":_vm.network,"buffer":_vm.buffer,"sidebar-state":_vm.sidebarState}}):_vm._e()],_vm._t("after")],2)]:[_c('div',{staticClass:"kiwi-container-empty"},[_c('h4',[_vm._v(_vm._s(_vm.$t('container_welcome')))]),_c('a',{staticClass:"u-button",on:{"click":function($event){$event.stopPropagation();return _vm.toggleStateBrowser($event)}}},[_vm._v(" "+_vm._s(_vm.$t('container_statebrowser'))+" ")])])]],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"kiwi-container-statebrowser-messagecount-close"},[_c('i',{staticClass:"fa fa-times",attrs:{"aria-hidden":"true"}})])}]

export { render, staticRenderFns }