"use strict";
'kiwi public';
/** @module */

var _interopRequireWildcard = require("@babel/runtime-corejs3/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.linkifyChannels = linkifyChannels;
exports.createNickColour = createNickColour;
exports.hexVal = hexVal;
exports.mapRange = mapRange;
exports.formatAndT = formatAndT;
exports.formatUser = formatUser;
exports.formatUserFull = formatUserFull;
exports.formatText = formatText;
exports.formatDuration = formatDuration;
exports.formatNumber = formatNumber;
exports.t = t;
exports.channelRegex = exports.urlRegex = void 0;

var _typeof2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/esm/typeof"));

var _parseFloat2 = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/parse-float"));

var _parseInt2 = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/parse-int"));

var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));

var _trim = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/trim"));

var _state = _interopRequireDefault(require("@/libs/state"));

var _ThemeManager = _interopRequireDefault(require("@/libs/ThemeManager"));

var _lodash = _interopRequireDefault(require("lodash"));

var ipRegex = _interopRequireWildcard(require("ip-regex"));

var _i18next = _interopRequireDefault(require("i18next"));

var Colours = _interopRequireWildcard(require("./Colours"));

var _Md = require("./Md5");

var urlRegex = new RegExp( // Detect either a protocol or 'www.' to start a URL
/(([A-Za-z][A-Za-z0-9-]*:\/\/)|(www\.))/.source + '(' + // Hostname and tld
/([\w\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF.-]+\.[a-zA-Z]{2,63})/.source + '|' + // IPv4 address
ipRegex.v4().source + '|' + // IPv6 address
'(\\[?' + ipRegex.v6().source + '\\]?)' + ')' + // Optional port..
/(:[0-9]+)?/.source + // Optional path..
/(\/[\w\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF!:.?$'()[\]*,;~+=&%@!\-/]*)?/.source + // Optional fragment
/(#.*)?/.source, 'i');
exports.urlRegex = urlRegex;
var channelRegex = /(^|\s)([@+~&%}]*)([#&][^ .,\007<>\n\r]+?)([:;.,<>\n\r]+)?$/i;
exports.channelRegex = channelRegex;

function linkifyChannels(word) {
  // "@#kiwiirc," = 3 parts. (prefix=@)(channel=#kiwiirc)(suffix=,)
  return word.replace(channelRegex, function (match, mLead, mPrefix, mChannel, mSuffix) {
    var _context, _context2, _context3, _context4;

    var chan = _lodash.default.escape((0, _trim.default)(mChannel).call(mChannel));

    var lead = _lodash.default.escape(mLead);

    var prefix = _lodash.default.escape(mPrefix);

    var suffix = _lodash.default.escape(mSuffix);

    var link = (0, _concat.default)(_context = "<a class=\"u-link kiwi-channel\" data-channel-name=\"".concat(chan, "\">")).call(_context, chan, "</a>");
    return (0, _concat.default)(_context2 = (0, _concat.default)(_context3 = (0, _concat.default)(_context4 = "".concat(lead)).call(_context4, prefix)).call(_context3, link)).call(_context2, suffix);
  });
}
/**
 * Convert a nickname string to a colour code
 */


function createNickColour(nick) {
  var nickLower = nick.toLowerCase(); // The HSL properties are based on this specific colour

  var startingColour = '#36809B'; // '#449fc1';

  var hash = (0, _Md.md5)(nickLower);
  var hueOffset = mapRange(hexVal(hash, 14, 3), 0, 4095, 0, 359);
  var satOffset = hexVal(hash, 17);
  var baseColour = Colours.rgb2hsl(Colours.hex2rgb(startingColour));
  baseColour.h = (baseColour.h * 360 - hueOffset + 360) % 360 / 360;

  if (satOffset % 2 === 0) {
    baseColour.s = Math.min(1, (baseColour.s * 100 + satOffset) / 100);
  } else {
    baseColour.s = Math.max(0, (baseColour.s * 100 - satOffset) / 100);
  }

  var themeMngr = _ThemeManager.default.instance();

  var brightness = themeMngr.themeVar('nick-brightness');

  if (brightness) {
    baseColour.l = (0, _parseInt2.default)(brightness, 10) / 100;
  }

  var rgb = Colours.hsl2rgb(baseColour);
  var nickColour = Colours.rgb2hex(rgb);
  return nickColour;
}
/**
 * Extract a substring from a hex string and parse it as an integer
 * @param {string} hash - Source hex string
 * @param {number} index - Start index of substring
 * @param {number} [length] - Length of substring. Defaults to 1.
 */


function hexVal(hash, index, len) {
  return (0, _parseInt2.default)(hash.substr(index, len || 1), 16);
}
/*
 * Re-maps a number from one range to another
 * http://processing.org/reference/map_.html
 */


function mapRange(value, vMin, vMax, dMin, dMax) {
  var vValue = (0, _parseFloat2.default)(value);
  var vRange = vMax - vMin;
  var dRange = dMax - dMin;
  return (vValue - vMin) * dRange / vRange + dMin;
}
/**
 * Format a string according to the configured textFormats, including a
 * translation for the %text formatting variable
 */


function formatAndT(formatId, formatParams, localeId, localeParams) {
  var body = t(localeId, localeParams);

  if (formatParams) {
    formatParams.text = body;
    body = formatText(formatId, formatParams);
  } else {
    body = formatText(formatId, {
      text: body
    });
  }

  return body;
}
/**
 * Create a user reference string similar to 'nick'
 */


function formatUser(fNick) {
  var nick = fNick; // Allow passing of a user object or irc-framework event

  if ((0, _typeof2.default)(nick) === 'object') {
    nick = nick.nick;
  }

  return formatText('user', {
    nick: nick
  });
}
/**
 * Create a full user reference similar to 'nick (user@host)'
 */


function formatUserFull(fNick, fUsername, fHost) {
  var nick = '';
  var username = '';
  var host = ''; // Allow passing of a user object or irc-framework event

  if ((0, _typeof2.default)(fNick) === 'object') {
    var user = fNick;
    nick = user.nick;
    username = user.username || user.ident;
    host = user.hostname || user.host;
  } else {
    nick = fNick;
    username = fUsername;
    host = fHost;
  }

  return formatText('user_full', {
    nick: nick,
    username: username,
    host: host
  });
}
/**
 * Format a string according to the configured textFormats
 */


function formatText(formatId, formatParams) {
  var format = (0, _state.default)().setting('textFormats.' + formatId);
  var params = formatParams; // Most texts only have a 'text' variable so allow passing of a string for this
  // variable as shorthand.

  if (typeof params === 'string') {
    params = {
      text: params
    };
  } // Expand a user mask into its individual parts (nick, ident, hostname)


  if (params.user) {
    params.nick = params.user.nick || '';
    params.username = params.user.username || '';
    params.host = params.user.hostname || '';
    params.prefix = params.user.prefix || '';
  } // Do the magic. Use the %shorthand syntax to produce output.


  var result = format.replace(/%([A-Z]{2,})/ig, function (match, key) {
    var ret = '';

    if (typeof params[key] !== 'undefined') {
      ret = params[key];
    }

    return ret;
  });
  return result;
} // Convert a given duration in seconds to human readable weeks,days,hours,minutes,seconds
// only showing the duration parts that are used eg 3666 --> 1 hour, 1 minute, 6 seconds


function formatDuration(timeSeconds) {
  var seconds = timeSeconds;
  var weeks = Math.floor(seconds / (3600 * 24 * 7));
  seconds -= weeks * 3600 * 24 * 7;
  var days = Math.floor(seconds / (3600 * 24));
  seconds -= days * 3600 * 24;
  var hours = Math.floor(seconds / 3600);
  seconds -= hours * 3600;
  var minutes = Math.floor(seconds / 60);
  seconds -= minutes * 60;
  var tmp = [];
  weeks && tmp.push(t('week', {
    count: weeks
  }));
  (weeks || days) && tmp.push(t('day', {
    count: days
  }));
  (days || hours) && tmp.push(t('hour', {
    count: hours
  }));
  (days || hours || minutes) && tmp.push(t('minute', {
    count: minutes
  }));
  tmp.push(t('second', {
    count: seconds
  }));
  return tmp.join(' ');
}

function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

function t(key, options) {
  var val = _i18next.default.t(key, options);

  if (!val) {
    var opts = options || {};
    opts.lng = 'en-us';
    val = _i18next.default.t(key, opts);
  }

  return val;
}
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["helpers"]) window._kiwi_exports["helpers"] = {};
window._kiwi_exports["helpers"]["TextFormatting"]
window._kiwi_exports.helpers.TextFormatting = exports.default ? exports.default : exports;
