"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.sanitizeNickname = void 0;

var sanitizeNickname = function sanitizeNickname(nickname) {
  if (!nickname) {
    throw new Error('nickname not valid');
  }

  return nickname.replace(/ /g, '_');
};

exports.sanitizeNickname = sanitizeNickname;