"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.Config = void 0;
var Config = {
  GRAPHQL_URL: 'https://virtualevent.cloudstar.io/demo/graphql'
};
exports.Config = Config;